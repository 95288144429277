<template>
  <div class="menu-body"
    v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="outer-block">
      <div class="tab-titles">
        <div class="tab-options">
          <div class="d-flex gap-closure-header-sub-con">
            <div class="in-action">
              <div class="primary-color tab-option-text" :class="{ 'tab-active-option': activeTab === 'global' }"
                @click="tabSwitch(1)">Global</div>
              <hr class="active-tab-scroll-hr" v-if="activeTab === 'global'" />
            </div>
          </div>
          <div class="d-flex gap-closure-header-sub-con">
            <div class="in-action">
              <div class="primary-color tab-option-text" :class="{ 'tab-active-option': activeTab === 'plant' }"
                @click="tabSwitch(2)">Plant</div>
              <hr class="active-tab-scroll-hr" v-if="activeTab === 'plant'" />
            </div>
          </div>
          <div class="d-flex gap-closure-header-sub-con">
            <div class="in-action">
              <div class="primary-color tab-option-text" :class="{ 'tab-active-option': activeTab === 'pillar' }"
                @click="tabSwitch(3)">Pillar</div>
              <hr class="active-tab-scroll-hr" v-if="activeTab === 'pillar'" />
            </div>
          </div>
          <div class="d-flex gap-closure-header-sub-con">
            <div class="in-action">
              <div class="primary-color tab-option-text" :class="{ 'tab-active-option': activeTab === 'data' }"
                @click="tabSwitch(4)">Data</div>
              <hr class="active-tab-scroll-hr" v-if="activeTab === 'data'" />
            </div>
          </div>
        </div>
        <div class="button-dd">
          <div class="download-view action-btn" v-if="activeTab === 'data'">
            <button type="button" @click="exportToExcel" style="background: transparent; border: transparent">
              <img alt="download" src="../../../assets/download_icon.svg" />
            </button>
          </div>
          <div class="download-view action-btn" @click="downloadPdf" v-if="activeTab !== 'data'">
            <img alt="download" src="../../../assets/download_icon.svg" />
          </div>

          <div class="acti-item-filter funnel-con">
            <div class="action-btn primary-color" v-b-modal.action-filter>
              <b-icon icon="funnel"></b-icon>
            </div>
          </div>
          <b-modal id="action-filter" ref="my-modal" :hide-header="true" :hide-footer="true" dialog-class="right">
            <ActionItemFilter :hideModal="hideModal" :noOfFilters="noOfFilters" :filterArray="filterArray"
              :applyFilter="applyFilter" :resetFilter="resetFilter" :changedFilter="changedFilter"
              :filterSelected="filterSelected" :onSelect="onSelect" :onRemove="onRemove" />
          </b-modal>
        </div>
      </div>
      <hr class="hr" />
      <!-- Global Chart -->
      <div ref="globalGap" id="globalGap"
        v-if="globalGapClosureDataStatus && globalGapClosureData && globalGapClosureData.completed">
        <div class="primary-color title-text center-aligned"
          v-if="globalGapClosureDataStatus && globalGapClosureData && globalGapClosureData.completed">
          {{ selectedYear }} Global Maturity Assessment Gap Closure Dashboard
        </div>

        <GlobalChart id="chart"
          v-if="globalGapClosureDataStatus && globalGapClosureData && globalGapClosureData.completed"
          :globalGapClosureData="globalGapClosureData" />
        <div class="sample-list">
          <div class="prior-years-actual"></div>
          <span class="sample-span">Prior Years Actual</span>
          <!-- <div class="committed"></div>
          <span class="sample-span">Forecast</span> -->
          <div class="committed"></div>
          <span class="sample-span">AOP Target</span>
          <div class="on-track"></div>
          <span class="sample-span">Completed</span>
          <div class="aop-values"></div>
          <span class="sample-span">Off Track</span>
        
        </div>
      </div>
      <!-- Global Chart -->
      <!-- Plant Chart -->
      <div ref="plantGap" id="plantGap"
        v-if="plantGapClosureDataStatus && plantGapClosureData && plantGapClosureData.completed && plantGapClosureData.completed.length > 0">
        <div class="primary-color title-text center-aligned"
          v-if="plantGapClosureDataStatus && plantGapClosureData && plantGapClosureData.completed && plantGapClosureData.completed.length > 0">
          {{ selectedYear }} {{ selectedRegions }} Maturity Assessment Gap Closure Dashboard
        </div>
        <PlantChart id="chart"
          v-if="plantGapClosureDataStatus && plantGapClosureData && plantGapClosureData.completed && plantGapClosureData.completed.length > 0"
          :plantGapClosureData="plantGapClosureData" :regionList="regionList" :activeTab="activeTab"
          :plantList="plantList" :handleDrillDown="handleDrillDown" :handleDrillDownPlant="handleDrillDownPlant" />
        <div class="sample-list">
          <div class="prior-years-actual"></div>
          <span class="sample-span">Prior Years Actual</span>
          <!-- <div class="committed"></div>
          <span class="sample-span">Forecast</span> -->
          <div class="committed"></div>
          <span class="sample-span">AOP Target</span>
          <div class="on-track"></div>
          <span class="sample-span">Completed</span>
          <div class="aop-values"></div>
          <span class="sample-span">Off Track</span>
          
        </div>
      </div>
      <!-- Plant Chart -->
      <!-- Pillar Chart -->
      <div ref="pillarGap" id="pillarGap"
        v-if="pillarGapClosureDataStatus && pillarGapClosureData && pillarGapClosureData.completed && pillarGapClosureData.completed.length > 0">
        <div class="primary-color title-text center-aligned"
          v-if="pillarGapClosureData && pillarGapClosureData.completed && pillarGapClosureData.completed.length > 0">
          {{ selectedYear }} {{ selectedRegions }} Maturity Assessment Gap Closure Dashboard
        </div>
        <PlantChart id="chart"
          v-if="pillarGapClosureDataStatus && pillarGapClosureData && pillarGapClosureData.completed && pillarGapClosureData.completed.length > 0"
          :activeTab="activeTab" :regionList="regionList" :plantGapClosureData="pillarGapClosureData"
          :plantList="plantList" :handleDrillDown="handleDrillDown" :handleDrillDownPlant="handleDrillDownPlant" />
        <div class="sample-list">
          <div class="prior-years-actual"></div>
          <span class="sample-span">Prior Years Actual</span>
          <!-- <div class="committed"></div>
          <span class="sample-span">Forecast</span> -->
          <div class="committed"></div>
          <span class="sample-span">AOP Target</span>
          <div class="on-track"></div>
          <span class="sample-span">Completed</span>
          <div class="aop-values"></div>
          <span class="sample-span">Off Track</span>
        </div>
      </div>
      <!-- Pillar Chart -->
      <!-- Data Table -->
      <div v-if="dataGapClosureDataStatus" id="data-block">
        <div class="primary-color title-text">Data</div>
        <b-table id="reports-gc-data-table" class="data-table gn-table" striped responsive hover :items="dataItems"
          :fields="dataFields">
          <template #cell(first)="row">
            <b-table id="inner-reports-gc-data-table" class="data-table gn-table" striped responsive hover
              :items="firstDataItems" :fields="row.item.first">
              <template #cell(region)="row">
                {{ uniqueRegion(row.item.region, row.index, firstDataItems) }}
              </template>
            </b-table>
          </template>
          <template #cell(completed)="row">
            <b-table id="inner-reports-gc-data-table" class="data-table gn-table" striped responsive hover
              :items="completedDataItems" :fields="row.item.completed"> </b-table>
          </template>
          <template #cell(committed)="row">
            <b-table id="inner-reports-gc-data-table" class="data-table gn-table" striped responsive hover
              :fields="row.item.committed" :items="committedDataItems"> </b-table>
          </template>
        </b-table>
      </div>
      <!-- Data Table -->
      <div id="print-chart"></div>
    </div>
  </div>
</template>
<script>
import GlobalChart from "./GlobalChart.vue";
import PlantChart from "./PlantChart.vue";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import XLSX from "xlsx";
import Api from "@/common/constants/api";
import ApiCalls from "@/common/api/apiCalls";
import ActionItemFilter from "@/components/Dashboard/ActionItemFilter.vue";
import PDFMake from "@/common/helper/PDFMake.vue";
import Helper from "@/common/helper/";
import Printd from "printd";
import colorCodes from "@/common/constants/colorCodes/index.js";
import html2canvas from "html2canvas";
import SpiderChartReport from "@/common/helper/SpiderChartReport.vue";
export default {
  name: "GapClosureDashboard",
  data() {
    return {
      handleDrillDownPlant: false,
      obj: new Printd(),
      applyFilterPlant: false,
      applyFilterPillar: false,
      applyFilterData: false,
      currentYear: new Date().getFullYear(),
      previousYear: new Date().getFullYear() - 1,
      pillarsData: [],
      pillarFields: {},
      plantDataBackup: {},
      pillarDataBackup: {},
      dataBackup: {},
      showLoader: false,
      globalGapClosureData: {},
      plantGapClosureData: {},
      pillarGapClosureData: {},
      regionList: [],
      plantList: [],
      activeTab: "global",
      selectedYear: new Date().getFullYear(),
      globalGapClosureDataStatus: false,
      plantGapClosureDataStatus: false,
      pillarGapClosureDataStatus: false,
      dataGapClosureDataStatus: false,
      noOfFilters: 4,
      filterArray: [],
      filterSelected: [],
      backupActionItems: [],
      dataItems: [],
      dataFields: [],
      firstDataItems: [],
      completedDataItems: [],
      committedDataItems: [],
      currentChart: "",
      onlyPillarList: [],
      output: null
    };
  },
  components: {
    Breadcrumb,
    Loader,
    GlobalChart,
    PlantChart,
    ApiCalls,
    Api,
    ActionItemFilter,
    // JsonExcel,
    XLSX
  },
  mixins: [PDFMake, SpiderChartReport],
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 3,
      subMenu: 1
    });
    this.$store.dispatch("breadcrumb", {
      title: "Gap Closure",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Reports / ", primary: true, redirect: "/" },
        { name: "Gap Closure", primary: false }
      ]
    });
  },
  created() {
    this.showLoader = true;
    this.applyFilterPlant = false;
    this.getPlantGapClosureData();
    this.applyFilterPillar = false;
    this.applyFilterData = false;
    this.getPillarList();
  },
  beforeMount() {
    this.filterArray = [];
    this.tabSwitch(1);
    this.filterArray = [
      {
        labelName: "Year",
        arrayValue: Helper.yearArrayFrom2020(), //[this.previousYear, this.currentYear],
        id: "Year"
      }
    ];
    this.getGapClosureData();
  },
  mounted() {
    this.filterArray = [];
    this.selectedYear = this.currentYear;
    this.tabSwitch(1);
    this.filterArray = [
      {
        labelName: "Year",
        arrayValue: Helper.yearArrayFrom2020(), //[this.previousYear, this.currentYear],
        id: "Year"
      }
    ];
    this.filterSelected = this.filterArray;
    // setTimeout(() => {

    this.getGapClosureData();
    // }, 8000);
  },
  computed: {
    selectedRegions() {
      let str = "";
      this.regionList.forEach((r) => {
        str += r + ", ";
      });
      str = str.slice(0, str.length - 2);
      return str;
    }
  },
  methods: {
    mod(n, m) {
      let remain = n % m;
      return Math.floor(remain >= 0 ? remain : remain + m);
    },
    downloadJPEG(ref) {
      html2canvas(this.$refs[ref], {
        logging: true,
        letterRendering: true,
        allowTaint: false,
        useCORS: true,
        scale: (1920 * 2) / window.innerWidth,
        backgroundColor: null
      })
        .then((canvas) => {
          let pdfMake = require("pdfmake/build/pdfmake.js");
          if (pdfMake.vfs == undefined) {
            let pdfFonts = require("pdfmake/build/vfs_fonts.js");
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
          }
          let img = new Image();
          img.src = canvas.toDataURL("image/jpg");
          let docDefinition = {
            pageSize: "A4",
            pageMargins: [10, 10, 10, 10],
            pageOrientation: "landscape",
            content: []
          };
          docDefinition.content.push({
            image: canvas.toDataURL("image/jpg"),
            width: 820,
            height: 300,
            positions: [
              {
                horizontalRatio: 0,
                left: 20,
                pageInnerHeight: 450.28,
                pageInnerWidth: 801.89,
                pageNumber: 1,
                pageOrientation: "landscape",
                top: 240,
                verticalRatio: 0.33312605489917385
              }
            ],
            x: 22,
            y: 240,
            alignment: "centre",
            margin: [0, 140, 50, 0]
          });
          pdfMake.createPdf(docDefinition).download("Chart.pdf");
        })
        .catch((error) => {
          console.log("ERROR SAVING FESTIVAL IMAGE", error);
        });
    },
    uniqueRegion(region, index, list) {
      if (index != 0) {
        if (list[index - 1].region == region) return "";
        else return region;
      } else return region;
    },
    handleDrillDown(value, activeTab) {
      if (value === "plant") {
        this.handleDrillDownPlant = true;
        if (activeTab === "plant") {
          this.plantGapClosureData = {
            type: "Plant",
            label: [],
            status: [],
            completed: [],
            committed: [],
            notSustained: [],
            offtrack: [],
            // aop: [],
          };
          setTimeout(() => {
            let labelList = [];
            let count = 0;
            let totalComp = [];
            let totalNotSustained = [];
            let totalComm = [];
            let totalStatus = [];
            let totalAop = [];
            let totalOffTrack = [];
            let regionList = {};
            let plantList = {};
            this.regionList = [];
            if (this.filterSelected.length > 1) {
              this.filterSelected[1].arrayValue.forEach((element, index) => {
                if (!regionList.hasOwnProperty(element.value) && element.checked === true && element.value !== "SelectAll") {
                  regionList[element.value] = element.value;
                  this.regionList.push(element.value);
                }
              });
              this.filterSelected[2].arrayValue.forEach((element, index) => {
                if (!plantList.hasOwnProperty(element.value.split(",")[0]) && element.checked === true && element.value !== "SelectAll") {
                  plantList[element.value.split(",")[0]] = element.value.split(",")[0];
                  count++;
                }
              });
              for (let index = 0; index < count; index++) {
                labelList = [
                  ...labelList,
                  ...[
                    "Actual " + (this.selectedYear - 2),
                    "Actual " + (this.selectedYear - 1),
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                    null
                  ]
                ];
              }
              let regionwiseComp = [];
              let regionwiseComm = [];
              let regionwiseNotSustained = [];
              let regionwiseOffTrack = [];
              let regionwiseAop = [];
              for (const key in regionList) {
                if (Object.hasOwnProperty.call(regionList, key)) {
                  for (const plant in plantList) {
                    this.plantDataBackup.plantData.forEach((element, index) => {
                      if (key === element.region && plant === element.plantName) {
                        element.completed.forEach((each, i) => {
                          //console.log(i,each,regionwiseComp)
                          regionwiseComp.push(each);
                        });
                      }
                    });
                  }

                  for (const plant in plantList) {
                    this.plantDataBackup.plantData.forEach((element, index) => {
                      if (key === element.region && plant === element.plantName) {
                        element.notSustained.forEach((each, i) => {

                          regionwiseNotSustained.push(each);
                        });
                      }
                    });
                  }

                  for (const plant in plantList) {
                    this.plantDataBackup.plantData.forEach((element, index) => {
                      if (key === element.region && plant === element.plantName) {
                        element.offtrack.forEach((each, i) => {

                          regionwiseOffTrack.push(each);
                        });
                        element.aop && element.aop.forEach((each, i) => {

                          regionwiseAop.push(each);
                        });
                      }
                    });
                  }

                  for (const plant in plantList) {
                    this.plantDataBackup.plantData.forEach((element, index) => {
                      if (key === element.region && plant === element.plantName) {
                        element.committed.forEach((each, i) => {

                          if (i > 1) regionwiseComm.push(each);
                          else if (i <= 1) regionwiseComm.push(null);
                        });
                      }
                    });
                  }
                  let regionStatus = ["Prior", "Prior", "", "", "", "", "", "", "", "", "", "", "", ""];
                  this.plantDataBackup.plantData.forEach((element, index) => {
                    for (const plant in plantList) {
                      if (key === element.region && plant === element.plantName) {
                        element.status.forEach((each, i) => {
                          if (i != 1 && i != 0) {
                            regionStatus[i] = this.selectStatus(each, regionStatus[i]);
                          }
                        });
                      }
                    }
                  });
                  totalStatus = [...totalStatus, ...regionStatus, "#fff"];
                }
              }

              totalComp = regionwiseComp;
              totalComm = regionwiseComm;
              totalNotSustained = regionwiseNotSustained;
              totalOffTrack = regionwiseOffTrack;
              totalAop = regionwiseAop;

              let statusColor = [];
              totalComm.forEach((status, index) => {
                if (totalComm[index] && totalComp[index] >= totalComm[index]) {
                  statusColor.push("ontrack");
                } else if (totalComm[index] && totalComp[index] < totalComm[index]) {
                  statusColor.push("offtrack");
                } else if (!totalComm[index]) {
                  statusColor.push("Prior");
                }
              });
              let length = (totalComp.length / 14).toFixed(0);
              let i = length;
              while (i >= 1) {
                totalComp.splice(i * 14, 0, 0);
                totalComm.splice(i * 14, 0, null);
                statusColor.splice(i * 14, 0, "#000");
                i--;
              }

              this.plantGapClosureData.committed = totalComm;
              this.plantGapClosureData.status = statusColor;
              this.plantGapClosureData.completed = totalComp;
              this.plantGapClosureData.notSustained = totalNotSustained;
              this.plantGapClosureData.offtrack = totalOffTrack;
              this.plantGapClosureData.aop = totalAop;
              this.plantGapClosureData.label = labelList;
            }
          }, 100);
        } else {
          this.pillarGapClosureData = {
            type: "Pillar",
            label: [],
            completed: [],
            committed: [],
            status: [],
            notSustained: [],
            offtrack: [],
            aop: [],
          };
          setTimeout(() => {
            let labelList = [];
            let count = 0;
            let totalComp = [];
            let totalComm = [];
            let totalNotSustained = [];
            let totalOffTrack = [];
            let totalAop = [];
            let totalStatus = [];
            let regionList = {};
            let plantList = {};
            let pillarList = {};
            this.regionList = [];
            this.plantList = [];
            if (this.filterSelected.length > 1) {
              this.filterSelected[1].arrayValue.forEach((element, index) => {
                if (!regionList.hasOwnProperty(element.value) && element.checked === true && element.value !== "SelectAll") {
                  regionList[element.value] = element.value;
                  this.regionList.push(element.value);
                }
              });
              this.filterSelected[2].arrayValue.forEach((element, index) => {
                if (!plantList.hasOwnProperty(element.value.split(",")[0]) && element.checked === true && element.value !== "SelectAll") {
                  plantList[element.value.split(",")[0]] = element.value.split(",")[0];
                  count++;
                  this.plantList.push(element.value.split(",")[0]);
                }
              });
              this.filterSelected[3].arrayValue.forEach((element, index) => {
                if (!pillarList.hasOwnProperty(element.value) && element.checked === true && element.value !== "SelectAll") {
                  pillarList[element.value] = element.value;
                }
              });
              for (let index = 0; index < count; index++) {
                labelList = [
                  ...labelList,
                  ...[
                    "Actual " + (this.selectedYear - 2),
                    "Actual " + (this.selectedYear - 1),
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                    null
                  ]
                ];
              }
              let regionwiseComp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              let regionwiseComm = [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              let regionwiseNotSustained = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              let regionwiseOffTrack = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              let regionwiseAop = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              let regionStatus = ["Prior", "Prior", "", "", "", "", "", "", "", "", "", "", "", ""];
              for (const key in regionList) {
                if (Object.hasOwnProperty.call(regionList, key)) {
                  let mul = 0;
                  for (const plant in plantList) {
                    for (const pillar in pillarList) {
                      this.pillarDataBackup.pillarData.forEach((element, index) => {
                        if (key === element.region && plant === element.plantName && pillar === element.pillarCode) {
                          element.completed.forEach((each, i) => {
                            i = i + mul * 15;
                            regionwiseComp[i] += each;
                          });
                        }
                      });
                    }

                    regionwiseComp.push(0);
                    mul++;
                    regionwiseComp = [...regionwiseComp, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                  }
                  for (const plant in plantList) {
                    for (const pillar in pillarList) {
                      this.pillarDataBackup.pillarData.forEach((element, index) => {
                        if (key === element.region && plant === element.plantName && pillar === element.pillarCode) {
                          element.notSustained.forEach((each, i) => {
                            i = i + mul * 15;
                            regionwiseNotSustained[i] += each;
                          });
                        }
                      });
                    }

                    regionwiseNotSustained.push(0);
                    mul++;
                    regionwiseNotSustained = [...regionwiseNotSustained, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                  }

                  mul = 0;
                  for (const plant in plantList) {
                    for (const pillar in pillarList) {
                      this.pillarDataBackup.pillarData.forEach((element, index) => {
                        if (key === element.region && plant === element.plantName && pillar === element.pillarCode) {
                          element.offtrack.forEach((each, i) => {
                            i = i + mul * 15;
                            regionwiseOffTrack[i] += each;
                          });
                        }
                      });
                    }

                    regionwiseOffTrack.push(0);
                    mul++;
                    regionwiseOffTrack = [...regionwiseOffTrack, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                  }

                  mul = 0;
                  for (const plant in plantList) {
                    for (const pillar in pillarList) {
                      this.pillarDataBackup.pillarData.forEach((element, index) => {
                        if (key === element.region && plant === element.plantName && pillar === element.pillarCode) {
                          element.aop && element.aop.forEach((each, i) => {
                            i = i + mul * 15;
                            regionwiseAop[i] += each;
                          });
                        }
                      });
                    }

                    regionwiseAop.push(0);
                    mul++;
                    regionwiseAop = [...regionwiseAop, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                  }

                  mul = 0;
                  for (const plant in plantList) {
                    for (const pillar in pillarList) {
                      this.pillarDataBackup.pillarData.forEach((element, index) => {
                        if (key === element.region && plant === element.plantName && pillar === element.pillarCode) {
                          element.committed.forEach((each, i) => {
                            if (i > 1) {
                              i = i + mul * 15;
                              regionwiseComm[i] += each;
                            } else if (i <= 1) {
                              i = i + mul * 15;
                              regionwiseComm[i] = null;
                            }
                          });
                        }
                      });
                    }

                    regionwiseComm.push(null);
                    mul++;
                    regionwiseComm = [...regionwiseComm, null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                  }

                  mul = 0;

                  for (const plant in plantList) {
                    for (const pillar in pillarList) {
                      this.pillarDataBackup.pillarData.forEach((element, index) => {
                        if (key === element.region && plant === element.plantName && pillar === element.pillarCode) {
                          element.status.forEach((each, i) => {
                            if (i > 1) {
                              i = i + mul * 15;
                              regionStatus[i] = this.selectStatus(each, regionStatus[i]);
                            } else if (i <= 1) {
                              i = i + mul * 15;
                              regionwiseComm[i] = "Prior";
                            }
                          });
                        }
                      });
                    }

                    regionStatus.push(null);
                    mul++;
                    regionStatus = [...regionStatus, "Prior", "Prior", "", "", "", "", "", "", "", "", "", "", "", ""];
                  }
                }
              }
              totalComp = regionwiseComp;
              totalComm = regionwiseComm;
              totalNotSustained = regionwiseNotSustained;
              totalOffTrack = regionwiseOffTrack;
              totalAop = regionwiseAop;
              totalStatus = regionStatus;
              console.log(totalStatus);
              let statusColor = [];
              totalComm.forEach((status, index) => {
                if (totalComm[index] && totalComm[index] !== "Prior" && totalComp[index] >= totalComm[index]) {
                  statusColor.push("ontrack");
                } else if (totalComm[index] && totalComm[index] !== "Prior" && totalComp[index] < totalComm[index]) {
                  statusColor.push("offtrack");
                } else if (!totalComm[index] || totalComm[index] === "Prior") {
                  statusColor.push("Prior");
                }
              });
              this.pillarGapClosureData.committed = totalComm;
              this.pillarGapClosureData.status = statusColor;
              this.pillarGapClosureData.completed = totalComp;
              this.pillarGapClosureData.notSustained = totalNotSustained;
              this.pillarGapClosureData.offtrack = totalOffTrack;
              this.pillarGapClosureData.aop = totalAop;
              this.pillarGapClosureData.label = labelList;
            }
          }, 100);
        }
      } else {
        this.handleDrillDownPlant = false;
        if (activeTab === "plant") {
          this.plantDataCorrection();
        } else {
          this.pillarDataCorrection();
        }
      }
    },
    onSelect(option, index) {
      console.log("onSelect");

      if (option.checked === true) {
        if (option.value === "SelectAll") {
          this.filterSelected[index].arrayValue[0].checked = false;
          for (const element of this.filterSelected[index].arrayValue) {
            element.checked = false;
          }
          if (index === 1) {
            this.filterSelected[2].arrayValue[0].checked = false;
          }
        } else {
          let indexLocal = this.filterSelected[index].arrayValue.findIndex((item) => item.label == option.label);

          this.filterSelected[index].arrayValue[indexLocal].checked = false;
        }
        if (index === 1) {
          if (option.value === "SelectAll") {
            for (const element of this.filterSelected[2].arrayValue) {
              element.checked = false;
            }
            if (index === 1) {
              this.filterSelected[2].arrayValue[0].checked = false;
            }
          } else {
            for (const element of this.filterSelected[1].arrayValue) {
              let region = element.label;
              let checkedRegion = element.checked;
              for (const element of this.filterSelected[2].arrayValue) {
                let regionPlant = element.label;
                if (region === regionPlant.split(", ")[1]) {
                  element.checked = checkedRegion;
                }
              }
            }
          }
        }

        let countAll = 0;
        let countUn = 0;
        let j = 0;

        for (j = 0; j < this.filterSelected[index].arrayValue.length; j++) {
          if (this.filterSelected[index].arrayValue[j].checked === true) {
            countAll++;
          }
          if (this.filterSelected[index].arrayValue[j].checked === false) {
            countUn++;
          }

          if (j === this.filterSelected[index].arrayValue.length - 1) {
            if (countAll === this.filterSelected[index].arrayValue.length - 1) {
              this.filterSelected[index].arrayValue[0].checked = true;
              if (index === 1) {
                this.filterSelected[2].arrayValue[0].checked = true;
              }
            }
            if (countUn === this.filterSelected[index].arrayValue.length - 1) {
              this.filterSelected[index].arrayValue[0].checked = false;
              if (index === 1) {
                this.filterSelected[2].arrayValue[0].checked = false;
              }
            }

            if (countAll !== this.filterSelected[index].arrayValue.length - 1 && countUn !== this.filterSelected[index].arrayValue.length - 1) {
              this.filterSelected[index].arrayValue[0].checked = false;
              if (index === 1) {
                this.filterSelected[2].arrayValue[0].checked = false;
              }
            }
            if (index === 1 && this.filterSelected[index].arrayValue[0].checked === true) {
              this.filterSelected[2].arrayValue[0].checked = true;
            } else if (index === 1 && this.filterSelected[index].arrayValue[0].checked === false) {
              this.filterSelected[2].arrayValue[0].checked = false;
            }
          }
        }
      } else {
        this.onRemove(option, index);
        if (index === 1) {
          this.filterSelected[2].arrayValue[0].checked = false;

        }
      }
    },

    onRemove(option, index) {
      if (index == 2 && option.label.indexOf(",")) {
        let region = option.label.substr(option.label.indexOf(", ") + 2, option.label.length - 1);
        let pos = this.filterSelected[1].arrayValue.findIndex((each) => {
          if (each.label == region) return each;
        });
        this.filterSelected[1].arrayValue[pos].checked = true;
      }
      if (option.checked === false) {
        if (option.value === "SelectAll") {
          this.filterSelected[index].arrayValue[0].checked = true;
          for (const element of this.filterSelected[index].arrayValue) {
            element.checked = true;
          }
          if (index === 1) {
            this.filterSelected[2].arrayValue[0].checked = true;
          }
        } else {
          let indexL = this.filterSelected[index].arrayValue.findIndex((item) => item.label == option.label);
          this.filterSelected[index].arrayValue[indexL].checked = true;
        }
        if (index === 1) {
          if (option.value === "SelectAll") {
            for (const element of this.filterSelected[2].arrayValue) {
              element.checked = true;
            }
            if (index === 1) {
              this.filterSelected[2].arrayValue[0].checked = true;
            }
          } else {
            for (const element of this.filterSelected[1].arrayValue) {
              let region = element.label;
              let checkedRegion = element.checked;
              for (const element of this.filterSelected[2].arrayValue) {
                let regionPlant = element.label;
                if (region === regionPlant.split(", ")[1]) {
                  element.checked = checkedRegion;
                }
              }
            }
          }
        }

        let countAll = 0;
        let countUn = 0;
        let j = 0;
        for (j = 0; j < this.filterSelected[index].arrayValue.length; j++) {
          if (this.filterSelected[index].arrayValue[j].checked === true) {
            countAll++;
          }
          if (this.filterSelected[index].arrayValue[j].checked === false) {
            countUn++;
          }

          if (j === this.filterSelected[index].arrayValue.length - 1) {
            if (countAll === this.filterSelected[index].arrayValue.length - 1) {
              this.filterSelected[index].arrayValue[0].checked = true;
              if (index === 1) {
                this.filterSelected[2].arrayValue[0].checked = true;
              }
            }
            if (countUn === this.filterSelected[index].arrayValue.length - 1) {
              this.filterSelected[index].arrayValue[0].checked = false;
              if (index === 1) {
                this.filterSelected[2].arrayValue[0].checked = false;
              }
            }

            if (countAll !== this.filterSelected[index].arrayValue.length - 1 && countUn !== this.filterSelected[index].arrayValue.length - 1) {
              this.filterSelected[index].arrayValue[0].checked = false;
              if (index === 1) {
                this.filterSelected[2].arrayValue[0].checked = false;
              }
            }
            if (index === 1 && this.filterSelected[index].arrayValue[0].checked === true) {
              this.filterSelected[2].arrayValue[0].checked = true;
            } else if (index === 1 && this.filterSelected[index].arrayValue[0].checked === false) {
              this.filterSelected[2].arrayValue[0].checked = false;
            }
          }
        }
      } else {
        this.onSelect(option, index);
        if (index === 1) {
          this.filterSelected[2].arrayValue[0].checked = true;
        }
      }
    },
    downloadPdf() {
      this.download = true;
      if (this.activeTab !== "data") {
        let ref = this.activeTab === "global" ? "globalGap" : this.activeTab === "plant" ? "plantGap" : this.activeTab === "pillar" ? "pillarGap" : "";
        console.log(ref);

        let id = this.activeTab === "global" ? "globalGap" : this.activeTab === "plant" ? "plantGap" : this.activeTab === "pillar" ? "pillarGap" : "";

        this.showLoader = true;
        this.pdfMakeCall(this.activeTab + "gapClosure", [id]);
        setTimeout(() => {
          this.showLoader = false;
        }, 10000);
      } else {
        Helper.export_table_to_excel("reports-gc-data-table", "Gap Closure Data");
      }
    },
    getGapClosureData() {
      ApiCalls.httpCallDetails(Api.GETGAPCLOSUREREPORTS + "?Year=" + this.selectedYear + "&Type=global", "get").then((data) => {
        this.showLoader = false;
        this.dataStatus();
        let toAdd = 0;
        for (let i = 2; i < data.data.completed.length; i++) {
          data.data.completed[i] += toAdd;
          data.data.committed[i] += toAdd;
        }
        this.globalGapClosureData = data.data;
      });
    },
    getPlantGapClosureData() {
      this.plantGapClosureData = {
        type: "Plant",
        label: [],
        completed: [],
        committed: [],
        status: [],
        notSustained: [],
        offtrack: [],
        aop: [],
      };
      ApiCalls.httpCallDetails(Api.GETGAPCLOSUREREPORTS + "?Year=" + this.selectedYear + "&Type=plant", "get").then((data) => {
        this.showLoader = false;
        this.regionList = [];
        //need to check here
        this.plantDataBackup = data.data;
        if (!this.applyFilterPlant) this.setFilterArrayForPlant();
        this.plantDataCorrection();
      });
    },
    setFilterArrayForPlant() {
      let regionListArray = this.plantDataBackup.plantData.map((value) => value.region);
      regionListArray = regionListArray.filter(function (item, pos) {
        return item !== null && regionListArray.indexOf(item) == pos;
      });
      for (let i = 0; i < regionListArray.length; i++) {
        regionListArray[i] = {
          value: regionListArray[i],
          label: regionListArray[i],
          checked: true
        };
      }
      regionListArray.unshift({
        value: "SelectAll",
        label: "All",
        checked: true
      });
      let regionPBUArray = this.plantDataBackup.plantData.map((value) => value.regionPBU);
      regionPBUArray = regionPBUArray.filter(function (item, pos) {
        return item !== null && regionPBUArray.indexOf(item) == pos;
      });
      for (let i = 0; i < regionPBUArray.length; i++) {
        regionPBUArray[i] = {
          value: regionPBUArray[i],
          label: regionPBUArray[i],
          checked: true
        };
      }
      let plantNameArray = this.plantDataBackup.plantData.map((value) => value.plantName + ", " + value.region);
      plantNameArray = plantNameArray.filter(function (item, pos) {
        return item !== null && plantNameArray.indexOf(item) == pos;
      });
      for (let i = 0; i < plantNameArray.length; i++) {
        plantNameArray[i] = {
          value: plantNameArray[i],
          label: plantNameArray[i],
          checked: true
        };
      }
      plantNameArray.sort((a, b) => {
        if (a.label.split(", ")[1] > b.label.split(", ")[1]) return 1;
        else if (a.label.split(", ")[1] < b.label.split(", ")[1]) return -1;
      });
      plantNameArray.unshift({
        value: "SelectAll",
        label: "All",
        checked: true
      });
      if (this.activeTab === "plant") {
        this.filterArray = [];
        this.filterArray.push({
          labelName: "Year",
          arrayValue: Helper.yearArrayFrom2020(), //[this.previousYear, this.currentYear],
          id: "Year"
        });
        this.filterArray.push({
          labelName: "Region",
          arrayValue: regionListArray,
          id: "Region",
          multiple: true
        });
        if (regionPBUArray.length > 0)
          this.filterArray.push({
            labelName: "Region - PBU",
            arrayValue: regionPBUArray,
            id: "PBU",
            multiple: true
          });
        this.filterArray.push({
          labelName: "Plant Name",
          arrayValue: plantNameArray,
          id: "PlantName",
          multiple: true
        });
        this.filterSelected = this.filterArray;
      }
    },
    plantDataCorrection() {
      this.plantGapClosureData = {
        type: "Plant",
        label: [],
        completed: [],
        committed: [],
        status: [],
        notSustained: [],
        offtrack: [],
        aop: [],
      };
      this.handleDrillDownPlant = false;
      setTimeout(() => {
        let labelList = [];
        let count = 0;
        let totalComp = [];
        let totalComm = [];
        let totalStatus = [];
        let notSustained = [];
        let offtrack = [];
        let aop = [];
        let regionList = {};
        let plantList = {};
        this.regionList = [];
        this.plantList = [];

        if (this.filterSelected.length > 1) {
          this.filterSelected[1].arrayValue.forEach((element, index) => {
            if (!regionList.hasOwnProperty(element.value) && element.checked === true && element.value !== "SelectAll") {
              regionList[element.value] = element.value;
              this.regionList.push(element.value);
              count++;
            }
          });
          this.filterSelected[2].arrayValue.forEach((element, index) => {
            if (!plantList.hasOwnProperty(element.value.split(",")[0]) && element.checked === true && element.value !== "SelectAll") {
              plantList[element.value.split(",")[0]] = element.value.split(",")[0];
              this.plantList.push(element.value.split(",")[0]);
            }
          });
          for (let index = 0; index < count; index++) {
            labelList = [
              ...labelList,
              ...[
                "Actual " + (this.selectedYear - 2),
                "Actual " + (this.selectedYear - 1),
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
                null
              ]
            ];
          }
          for (const key in regionList) {
            if (Object.hasOwnProperty.call(regionList, key)) {
              let regionwiseComp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              for (const plant in plantList) {
                this.plantDataBackup.plantData.forEach((element, index) => {
                  if (key === element.region && plant === element.plantName) {
                    element.completed.forEach((each, i) => {
                      regionwiseComp[i] += each;
                    });
                  }
                });
              }

              totalComp = [...totalComp, ...regionwiseComp, 0];

              let regionwiseNotSustained = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              for (const plant in plantList) {
                this.plantDataBackup.plantData.forEach((element, index) => {
                  if (key === element.region && plant === element.plantName) {
                    element.notSustained.forEach((each, i) => {
                      regionwiseNotSustained[i] += each;
                    });
                  }
                });
              }
              notSustained = [...notSustained, ...regionwiseNotSustained, 0];

              let regionwiseOffTrack = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              for (const plant in plantList) {
                this.plantDataBackup.plantData.forEach((element, index) => {
                  if (key === element.region && plant === element.plantName) {
                    element.offtrack.forEach((each, i) => {
                      regionwiseOffTrack[i] += each;
                    });
                  }
                });
              }
              offtrack = [...offtrack, ...regionwiseOffTrack, 0];

              let regionwiseAop = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              for (const plant in plantList) {
                this.plantDataBackup.plantData.forEach((element, index) => {
                  if (key === element.region && plant === element.plantName) {
                    element.aop && element.aop.forEach((each, i) => {
                      regionwiseAop[i] += each;
                    });
                  }
                });
              }
              aop = [...aop, ...regionwiseAop, 0];

              let regionwiseComm = [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              for (const plant in plantList) {
                this.plantDataBackup.plantData.forEach((element, index) => {
                  if (key === element.region && plant === element.plantName) {
                    element.committed.forEach((each, i) => {
                      if (i > 1) regionwiseComm[i] += each;
                    });
                  }
                });
              }
              totalComm = [...totalComm, ...regionwiseComm, 0];
              let regionStatus = ["Prior", "Prior", "", "", "", "", "", "", "", "", "", "", "", ""];
              this.plantDataBackup.plantData.forEach((element, index) => {
                if (key === element.region) {
                  if (this.plantList.length == 2) {
                    for (const plant in plantList) {
                      if (plant === element.plantName) {
                        element.status.forEach((each, i) => {
                          if (i != 1 && i != 0) {
                            regionStatus[i] = this.selectStatus(each, regionStatus[i]);
                          }
                        });
                      }
                    }
                  } else {
                    element.status.forEach((each, i) => {
                      if (i != 1 && i != 0) {
                        regionStatus[i] = this.selectStatus(each, regionStatus[i]);
                      }
                    });
                  }
                }
              });
              totalStatus = [...totalStatus, ...regionStatus, "#fff"];
            }
          }
          let statusColor = [];
          totalComm.forEach((status, index) => {
            if (totalComm[index] && totalComp[index] >= totalComm[index]) {
              statusColor.push("ontrack");
            } else if (totalComm[index] && totalComp[index] < totalComm[index]) {
              statusColor.push("offtrack");
            } else if (!totalComm[index]) {
              statusColor.push("Prior");
            }
          });
          this.plantGapClosureData = {
            label: labelList,
            completed: totalComp,
            status: statusColor,
            committed: totalComm,
            notSustained: notSustained,
            offtrack: offtrack,
            aop: aop
          };
          let countChecked = 0;
          this.filterSelected[2].arrayValue.forEach((i) => {
            if (i.checked) countChecked++;
          });
          if (countChecked === 1) {
            this.handleDrillDown("plant", this.activeTab);
          }
        }
      }, 100);
    },
    selectStatus(each, prev) {
      if (prev && each) {
        if (each === "offtrack" && prev === "offtrack") {
          return "offtrack";
        }
        if (each === "ontrack" && prev === "offtrack") {
          return "offtrack";
        }
        if (each === "offtrack" && prev === "ontrack") {
          return "offtrack";
        }
        if (each === "ontrack" && prev === "ontrack") {
          return "ontrack";
        }
      }
      return each;
    },
    getPillarGapClosureData() {
      ApiCalls.httpCallDetails(Api.GETGAPCLOSUREREPORTS + "?Year=" + this.selectedYear + "&Type=pillars", "get").then((data) => {
        this.showLoader = true;
        this.regionList = [];
        this.pillarGapClosureData = {};
        this.pillarDataBackup = data.data;
        if (!this.applyFilterPillar)
          setTimeout(() => {
            this.showLoader = false;
            this.setFilterForPillars();
          }, 7000);
        setTimeout(() => {
          this.pillarDataCorrection();
          this.showLoader = false;
        }, 8000);
      });
    },
    getPillarList() {
      ApiCalls.httpCallDetails(Api.GETPILLARDROPDOWNLIST, "get").then((data) => {

        this.onlyPillarList = data.data;
      });
    },
    setFilterForPillars() {
      if (this.pillarDataBackup) {
        let regionListArray = this.pillarDataBackup.pillarData.map((value) => value.region);
        regionListArray = regionListArray.filter(function (item, pos) {
          return item !== null && regionListArray.indexOf(item) == pos;
        });
        for (let i = 0; i < regionListArray.length; i++) {
          regionListArray[i] = {
            value: regionListArray[i],
            label: regionListArray[i],
            checked: true
          };
        }
        regionListArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: true
        });
        let regionPBUArray = this.pillarDataBackup.pillarData.map((value) => value.regionPBU);
        regionPBUArray = regionPBUArray.filter(function (item, pos) {
          return item !== null && regionPBUArray.indexOf(item) == pos;
        });
        for (let i = 0; i < regionPBUArray.length; i++) {
          regionPBUArray[i] = {
            value: regionPBUArray[i],
            label: regionPBUArray[i],
            checked: true
          };
        }
        let plantNameArray = this.pillarDataBackup.pillarData.map((value) => value.plantName + ", " + value.region);
        plantNameArray = plantNameArray.filter(function (item, pos) {
          return item !== null && plantNameArray.indexOf(item) == pos;
        });
        for (let i = 0; i < plantNameArray.length; i++) {
          plantNameArray[i] = {
            value: plantNameArray[i],
            label: plantNameArray[i],
            checked: true
          };
        }
        plantNameArray.sort((a, b) => {
          if (a.label.split(", ")[1] > b.label.split(", ")[1]) return 1;
          else if (a.label.split(", ")[1] < b.label.split(", ")[1]) return -1;
        });
        plantNameArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: true
        });
        let pillarListArray = this.pillarDataBackup.pillarData.map((value) => value.pillarCode);

        pillarListArray = pillarListArray.filter(function (item, pos) {
          return item !== null && pillarListArray.indexOf(item) == pos;
        });
        for (let i = 0; i < pillarListArray.length; i++) {
          let id = this.onlyPillarList.find((item) => {
            if (item.pillarCode === pillarListArray[i]) return item;
          });
          pillarListArray[i] = {
            id: id.pillarId,
            value: pillarListArray[i],
            label: pillarListArray[i],
            checked: true
          };
        }
        pillarListArray.sort((a, b) => {
          if (a.id > b.id) return 1;
          if (a.id < b.id) return -1;
        });
        pillarListArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: true
        });
        if (this.activeTab === "pillar") {
          this.filterArray = [];
          this.filterArray.push({
            labelName: "Year",
            arrayValue: Helper.yearArrayFrom2020(), //[this.previousYear, this.currentYear],
            id: "Year"
          });
          this.filterArray.push({
            labelName: "Region",
            arrayValue: regionListArray,
            id: "Region",
            multiple: true
          });
          if (regionPBUArray.length > 0)
            this.filterArray.push({
              labelName: "Region - PBU",
              arrayValue: regionPBUArray,
              id: "PBU",
              multiple: true
            });
          this.filterArray.push({
            labelName: "Plant Name",
            arrayValue: plantNameArray,
            id: "PlantName",
            multiple: true
          });
          this.filterArray.push({
            labelName: "Pillar Name",
            arrayValue: pillarListArray,
            id: "PillarName",
            multiple: true
          });
          this.filterSelected = this.filterArray;
        }
      }
    },
    exportToExcel() {
      // On Click Excel download button
      let sheetData = [];
      console.log(this.pillarFields);
      this.pillarsData.forEach((i) => {
        let obj = {};
        for (const key in this.pillarFields) {
          if (Object.hasOwnProperty.call(this.pillarFields, key) && !Object.hasOwnProperty.call(this.pillarFields[key], "field")) {
            const element = this.pillarFields[key];
            obj[key] = i[element] ? i[element] : "";
          } else {
            const element = this.pillarFields[key];
            obj[key] = i[element.field].length ? element.callback(i[element.field]) : "";
          }
        }
        sheetData.push(obj);
      });
      console.log(sheetData);
      // export json to Worksheet of Excel
      // only array possible
      let sheet = XLSX.utils.json_to_sheet(sheetData);

      let wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, sheet, "sheet"); // sheetAName is name of Worksheet
      // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')

      // export Excel file
      XLSX.writeFile(wb, "Gap Closure Data .xlsx"); // name of the file is 'book.xlsx'
    },
    pillarDataCorrection() {
      this.pillarGapClosureData = {
        type: "Pillar",
        label: [],
        completed: [],
        committed: [],
        status: [],
        notSustained: [],
        offtrack: [],
        aop: [],
      };
      this.handleDrillDownPlant = false;
      setTimeout(() => {
        let labelList = [];
        let count = 0;
        let totalComp = [];
        let totalComm = [];
        let totalNotSustained = [];
        let totalOffTrack = [];
        let totalAop = [];
        let totalStatus = [];
        let regionList = {};
        let plantList = {};
        let pillarList = {};
        this.regionList = [];
        this.plantList = [];
        if (this.filterSelected && this.filterSelected.length > 1) {
          this.filterSelected[1].arrayValue.forEach((element, index) => {
            if (!regionList.hasOwnProperty(element.value) && element.checked === true && element.value !== "SelectAll") {
              regionList[element.value] = element.value;
              this.regionList.push(element.value);
              count++;
            }
          });
          this.filterSelected[2].arrayValue.forEach((element, index) => {
            if (!plantList.hasOwnProperty(element.value.split(",")[0]) && element.checked === true && element.value !== "SelectAll") {
              plantList[element.value.split(",")[0]] = element.value.split(",")[0];
              this.plantList.push(element.value.split(",")[0]);
            }
          });
          this.filterSelected[3].arrayValue.forEach((element, index) => {
            if (!pillarList.hasOwnProperty(element.value) && element.checked === true && element.value !== "SelectAll") {
              pillarList[element.value] = element.value;
            }
          });
          for (let index = 0; index < count; index++) {
            labelList = [
              ...labelList,
              ...[
                "Actual " + (this.selectedYear - 2),
                "Actual " + (this.selectedYear - 1),
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
                null
              ]
            ];
          }
          for (const key in regionList) {
            if (Object.hasOwnProperty.call(regionList, key)) {
              let regionwiseComp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              for (const plant in plantList) {
                for (const pillar in pillarList) {
                  this.pillarDataBackup.pillarData.forEach((element, index) => {
                    if (key === element.region && plant === element.plantName && pillar === element.pillarCode) {
                      element.completed.forEach((each, i) => {
                        regionwiseComp[i] += each;
                      });
                    }
                  });
                }
              }

              totalComp = [...totalComp, ...regionwiseComp, 0];

              let regionwiseNotSustained = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              for (const plant in plantList) {
                for (const pillar in pillarList) {
                  this.pillarDataBackup.pillarData.forEach((element, index) => {
                    if (key === element.region && plant === element.plantName && pillar === element.pillarCode) {
                      element.notSustained.forEach((each, i) => {
                        regionwiseNotSustained[i] += each;
                      });
                    }
                  });
                }
              }

              totalNotSustained = [...totalNotSustained, ...regionwiseNotSustained, 0];

              let regionwiseOffTrack = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              for (const plant in plantList) {
                for (const pillar in pillarList) {
                  this.pillarDataBackup.pillarData.forEach((element, index) => {
                    if (key === element.region && plant === element.plantName && pillar === element.pillarCode) {
                      element.offtrack.forEach((each, i) => {
                        regionwiseOffTrack[i] += each;
                      });
                    }
                  });
                }
              }

              totalOffTrack = [...totalOffTrack, ...regionwiseOffTrack, 0];

              let regionwiseAop = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              for (const plant in plantList) {
                for (const pillar in pillarList) {
                  this.pillarDataBackup.pillarData.forEach((element, index) => {
                    if (key === element.region && plant === element.plantName && pillar === element.pillarCode) {
                      element.aop && element.aop.forEach((each, i) => {
                        regionwiseAop[i] += each;
                      });
                    }
                  });
                }
              }

              totalAop = [...totalAop, ...regionwiseAop, 0];


              let regionwiseComm = [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              for (const plant in plantList) {
                for (const pillar in pillarList) {
                  this.pillarDataBackup.pillarData.forEach((element, index) => {
                    if (key === element.region && plant === element.plantName && pillar === element.pillarCode) {
                      element.committed.forEach((each, i) => {
                        if (i > 1) regionwiseComm[i] += each;
                      });
                    }
                  });
                }
              }
              totalComm = [...totalComm, ...regionwiseComm, 0];
              let regionStatus = ["Prior", "Prior", "", "", "", "", "", "", "", "", "", "", "", ""];
              this.pillarDataBackup.pillarData.forEach((element, index) => {
                if (key === element.region) {
                  element.status.forEach((each, i) => {
                    if (i != 1 && i != 0) {
                      regionStatus[i] = this.selectStatus(each, regionStatus[i]);
                    }
                  });
                }
              });
              totalStatus = [...totalStatus, ...regionStatus, "#fff"];
            }
          }
          let statusColor = [];
          totalComm.forEach((status, index) => {
            if (totalComm[index] && totalComm[index] !== "Prior" && totalComp[index] >= totalComm[index]) {
              statusColor.push("ontrack");
            } else if (totalComm[index] && totalComm[index] !== "Prior" && totalComp[index] < totalComm[index]) {
              statusColor.push("offtrack");
            } else if (!totalComm[index] || totalComm[index] === "Prior") {
              statusColor.push("Prior");
            }
          });
          this.pillarGapClosureData.committed = totalComm;
          this.pillarGapClosureData.status = statusColor;
          this.pillarGapClosureData.completed = totalComp;
          this.pillarGapClosureData.notSustained = totalNotSustained;
          this.pillarGapClosureData.offtrack = totalOffTrack;
          this.pillarGapClosureData.aop = totalAop;
          this.pillarGapClosureData.label = labelList;
        }
      }, 100);
    },
    getDataGapClosureData() {
      ApiCalls.httpCallDetails(Api.GETGAPCLOSUREREPORTS + "?Year=" + this.selectedYear + "&Type=pillars", "get").then((data) => {
        this.regionList = [];
        this.dataBackup = data.data;
        this.dataBackup.pillarData = this.dataBackup.pillarData.filter((item, pos, self) => {
          return pos === self.findIndex((t) => t.region === item.region && t.regionPBU === item.regionPBU && t.plantName === item.plantName && t.pillarCode === item.pillarCode);
        });
        let dataCheck = this.dataBackup.pillarData.filter((val) => val.plantName === "Aurangabad");
        console.log(dataCheck);
        if (!this.applyFilterData)
          setTimeout(() => {
            this.showLoader = false;
            this.setFilterForData();
          }, 7000);
        setTimeout(() => this.dataCorrection(), 8000);
      });
    },
    setFilterForData() {
      if (this.dataBackup) {
        let regionListArray = this.dataBackup.pillarData.map((value) => value.region);
        regionListArray = regionListArray.filter(function (item, pos) {
          return item !== null && regionListArray.indexOf(item) == pos;
        });
        for (let i = 0; i < regionListArray.length; i++) {
          regionListArray[i] = {
            value: regionListArray[i],
            label: regionListArray[i],
            checked: true
          };
        }
        regionListArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: true
        });
        let regionPBUArray = this.dataBackup.pillarData.map((value) => value.regionPBU);
        regionPBUArray = regionPBUArray.filter(function (item, pos) {
          return item !== null && regionPBUArray.indexOf(item) == pos;
        });
        for (let i = 0; i < regionPBUArray.length; i++) {
          regionPBUArray[i] = {
            value: regionPBUArray[i],
            label: regionPBUArray[i],
            checked: true
          };
        }
        let plantNameArray = this.dataBackup.pillarData.map((value) => value.plantName + ", " + value.region);
        plantNameArray = plantNameArray.filter(function (item, pos) {
          return item !== null && plantNameArray.indexOf(item) == pos;
        });
        for (let i = 0; i < plantNameArray.length; i++) {
          plantNameArray[i] = {
            value: plantNameArray[i],
            label: plantNameArray[i],
            checked: true
          };
        }
        plantNameArray.sort((a, b) => {
          if (a.label.split(", ")[1] > b.label.split(", ")[1]) return 1;
          else if (a.label.split(", ")[1] < b.label.split(", ")[1]) return -1;
        });
        plantNameArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: true
        });
        let pillarListArray = this.dataBackup.pillarData.map((value) => value.pillarCode);
        pillarListArray = pillarListArray.filter(function (item, pos) {
          return item !== null && pillarListArray.indexOf(item) == pos;
        });
        for (let i = 0; i < pillarListArray.length; i++) {
          pillarListArray[i] = {
            value: pillarListArray[i],
            label: pillarListArray[i],
            checked: true
          };
        }
        pillarListArray.unshift({
          value: "SelectAll",
          label: "All",
          checked: true
        });
        if (this.activeTab === "data") {
          this.filterArray = [];
          this.filterArray.push({
            labelName: "Year",
            arrayValue: Helper.yearArrayFrom2020(), //[this.previousYear, this.currentYear],
            id: "Year"
          });
          this.filterArray.push({
            labelName: "Region",
            arrayValue: regionListArray,
            id: "Region",
            multiple: true
          });
          if (regionPBUArray.length > 0)
            this.filterArray.push({
              labelName: "Region - PBU",
              arrayValue: regionPBUArray,
              id: "PBU",
              multiple: true
            });
          this.filterArray.push({
            labelName: "Plant Name",
            arrayValue: plantNameArray,
            id: "PlantName",
            multiple: true
          });
          this.filterArray.push({
            labelName: "Pillar Name",
            arrayValue: pillarListArray,
            id: "PillarName",
            multiple: true
          });
          this.filterSelected = this.filterArray;
        }
      }
    },
    dataCorrection() {
      let regionList = {};
      let plantList = {};
      let pillarList = {};
      this.regionList = [];
      this.firstDataItems = [];
      this.dataItems = [];
      this.completedDataItems = [];
      this.committedDataItems = [];
      if (this.filterSelected && this.filterSelected.length > 1) {
        this.filterSelected[1].arrayValue.forEach((element, index) => {
          if (!regionList.hasOwnProperty(element.value) && element.checked === true && element.value !== "SelectAll") {
            regionList[element.value] = element.value;
            this.regionList.push(element.value);
          }
        });
        this.filterSelected[2].arrayValue.forEach((element, index) => {
          if (!plantList.hasOwnProperty(element.value.split(",")[0]) && element.checked === true && element.value !== "SelectAll") {
            plantList[element.value.split(",")[0]] = element.value.split(",")[0];
          }
        });
        this.filterSelected[3].arrayValue.forEach((element, index) => {
          if (!pillarList.hasOwnProperty(element.value) && element.checked === true && element.value !== "SelectAll") {
            pillarList[element.value] = element.value;
          }
        });
      }
      this.pillarsData = this.dataBackup.pillarData;
      this.pillarsData = this.pillarsData.filter((item, pos, self) => {
        let foundIndex = self.findIndex((t) => t.region === item.region && t.regionPBU === item.regionPBU && t.plantName === item.plantName && t.pillarCode === item.pillarCode);
        return pos === foundIndex;
      });
      let dataPillarTemp = [];
      for (const key in regionList) {
        if (Object.hasOwnProperty.call(regionList, key)) {
          for (const plant in plantList) {
            let completedList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            let committedList = [null, null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            for (const pillar in pillarList) {
              this.pillarsData.forEach((element, index) => {
                if (key === element.region && plant === element.plantName && pillar === element.pillarCode) {
                  element.completed.forEach((data, i) => {
                    completedList[i] = completedList[i] + data;
                  });
                  element.committed.forEach((data, i) => {
                    committedList[i] = committedList[i] + data;
                  });
                  dataPillarTemp.push(element);
                }
              });
            }
            //total field
            dataPillarTemp.push({
              plantName: plant,
              regionPBU: null,
              region: key,
              label: null,
              completed: completedList,
              status: [
                "Prior",
                "Prior",
                "ontrack",
                "offtrack",
                "offtrack",
                "offtrack",
                "offtrack",
                "offtrack",
                "offtrack",
                "offtrack",
                "offtrack",
                "offtrack",
                "offtrack",
                "offtrack"
              ],
              committed: committedList,
              pillarCode: "Total"
            });
          }
        }
      }
      this.pillarsData = dataPillarTemp;
      this.pillarFields = {
        Region: "region",
        "Region PBU": "regionPBU",
        "Plant Name": "plantName",
        Pillar: "pillarCode",
        "Completed Practices ->": " ",

        Jan: {
          field: "completed",
          callback: (value) => {
            return `${value[2]}`;
          }
        },
        Feb: {
          field: "completed",
          callback: (value) => {
            return `${value[3]}`;
          }
        },
        Mar: {
          field: "completed",
          callback: (value) => {
            return `${value[4]}`;
          }
        },
        Apr: {
          field: "completed",
          callback: (value) => {
            return `${value[5]}`;
          }
        },
        May: {
          field: "completed",
          callback: (value) => {
            return `${value[6]}`;
          }
        },
        Jun: {
          field: "completed",
          callback: (value) => {
            return `${value[7]}`;
          }
        },
        Jul: {
          field: "completed",
          callback: (value) => {
            return `${value[8]}`;
          }
        },
        Aug: {
          field: "completed",
          callback: (value) => {
            return `${value[9]}`;
          }
        },
        Sep: {
          field: "completed",
          callback: (value) => {
            return `${value[10]}`;
          }
        },
        Oct: {
          field: "completed",
          callback: (value) => {
            return `${value[11]}`;
          }
        },
        Nov: {
          field: "completed",
          callback: (value) => {
            return `${value[12]}`;
          }
        },
        Dec: {
          field: "completed",
          callback: (value) => {
            return `${value[13]}`;
          }
        },
        "Forecaste Practices ->": " ",

        "Jan ": {
          field: "committed",
          callback: (value) => {
            return `${value[2]}`;
          }
        },
        "Feb ": {
          field: "committed",
          callback: (value) => {
            return `${value[3]}`;
          }
        },
        "Mar ": {
          field: "committed",
          callback: (value) => {
            return `${value[4]}`;
          }
        },
        "Apr ": {
          field: "committed",
          callback: (value) => {
            return `${value[5]}`;
          }
        },
        "May ": {
          field: "committed",
          callback: (value) => {
            return `${value[6]}`;
          }
        },
        "Jun ": {
          field: "committed",
          callback: (value) => {
            return `${value[7]}`;
          }
        },
        "Jul ": {
          field: "committed",
          callback: (value) => {
            return `${value[8]}`;
          }
        },
        "Aug ": {
          field: "committed",
          callback: (value) => {
            return `${value[9]}`;
          }
        },
        "Sep ": {
          field: "committed",
          callback: (value) => {
            return `${value[10]}`;
          }
        },
        "Oct ": {
          field: "committed",
          callback: (value) => {
            return `${value[11]}`;
          }
        },
        "Nov ": {
          field: "committed",
          callback: (value) => {
            return `${value[12]}`;
          }
        },
        "Dec ": {
          field: "committed",
          callback: (value) => {
            return `${value[13]}`;
          }
        }
      };

      this.pillarFields["Actual " + (this.selectedYear - 2)] = {
        field: "completed",
        callback: (value) => {
          return `${value[0]}`;
        }
      };
      this.pillarFields["Actual " + (this.selectedYear - 1)] = {
        field: "completed",
        callback: (value) => {
          return `${value[1]}`;
        }
      };
      this.pillarFields["Actual " + (this.selectedYear - 2) + " "] = {
        field: "committed",
        callback: (value) => {
          return "";
        }
      };
      this.pillarFields["Actual " + (this.selectedYear - 1) + " "] = {
        field: "committed",
        callback: (value) => {
          return "";
        }
      };

      let key = Object.keys(this.pillarFields);
      key.splice(5, 0, key[30]);
      key.splice(6, 0, key[32]);
      key.splice(20, 0, key[34]);
      key.splice(21, 0, key[36]);
      key.splice(34, 4);
      let tempFields = this.pillarFields;
      this.pillarFields = {};
      for (const element of key) {
        let k = element;
        this.pillarFields[k] = tempFields[k];
      }
      this.dataFields = [
        {
          key: "first",
          sortable: false,
          label: ""
        },
        {
          key: "completed",
          sortable: false,
          label: "Completed"
        },
        {
          key: "committed",
          sortable: false,
          label: "Forecaste"
        }
      ];

      let firstDataFields = [
        {
          key: "region",
          sortable: false,
          label: ""
        },
        {
          key: "regionPBU",
          sortable: false,
          label: ""
        },
        {
          key: "plant",
          sortable: false,
          label: ""
        }
      ];

      let labelArr = [];
      this.dataBackup.pillarData[0].label.forEach((element) => {
        let obj = {};
        obj.key = element;
        obj.sortable = false;
        obj.label = element;
        labelArr.push(obj);
      });
      this.dataItems = [
        {
          first: firstDataFields,
          completed: labelArr,
          committed: labelArr
        }
      ];

      for (const key in regionList) {
        if (Object.hasOwnProperty.call(regionList, key)) {
          for (const plant in plantList) {
            this.dataBackup.pillarData.forEach((element, index) => {
              if (key === element.region && plant === element.plantName) {
                let obj = {};
                obj["region"] = element.region;
                obj["regionPBU"] = element.regionPBU;
                obj["plant"] = element.plantName;
                this.firstDataItems.push(obj);
              }
            });
            // }
          }
        }
      }
      this.firstDataItems = this.firstDataItems.filter((item, pos, self) => {
        return pos === self.findIndex((t) => t.region === item.region && t.regionPBU === item.regionPBU && t.plant === item.plant);
      });
      for (const key in regionList) {
        if (Object.hasOwnProperty.call(regionList, key)) {
          for (const plant in plantList) {
            let completeobj = {};
            let committedobj = {};
            for (const pillar in pillarList) {
              this.dataBackup.pillarData.forEach((element, index) => {
                if (key === element.region && plant === element.plantName && pillar === element.pillarCode) {
                  for (let i = 0; i < element.label.length; i++) {
                    if (completeobj[element.label[i]]) {
                      completeobj[element.label[i]] = completeobj[element.label[i]] + parseInt(element.completed[i]);
                      if (i > 1) {
                        committedobj[element.label[i]] = committedobj[element.label[i]] + parseInt(element.committed[i]);
                      } else {
                        committedobj[element.label[i]] += 0;
                      }
                    } else {
                      completeobj[element.label[i]] = parseInt(element.completed[i]);
                      if (i > 1) {
                        if (committedobj[element.label[i]]) committedobj[element.label[i]] = committedobj[element.label[i]] + parseInt(element.committed[i]);
                        else committedobj[element.label[i]] = parseInt(element.committed[i]);
                      } else {
                        committedobj[element.label[i]] = 0;
                      }
                    }
                  }
                }
              });
            }
            if (Object.keys(completeobj).length !== 0) this.completedDataItems.push(completeobj);
            if (Object.keys(committedobj).length !== 0) this.committedDataItems.push(committedobj);
          }
        }
      }
    },
    onYearChange() {
      this.showLoader = true;
      this.getGapClosureData();
    },
    dataStatus() {
      this.globalGapClosureDataStatus = this.activeTab === "global";
      this.plantGapClosureDataStatus = this.activeTab === "plant";
      this.pillarGapClosureDataStatus = this.activeTab === "pillar";
      this.dataGapClosureDataStatus = this.activeTab === "data";
    },
    tabSwitch(pos) {
      // this.activeTab
      switch (pos) {
        case 1:
          this.activeTab = "global";
          this.showLoader = true;
          this.filterArray = [
            {
              labelName: "Year",
              arrayValue: Helper.yearArrayFrom2020(), //[this.previousYear, this.currentYear],
              id: "Year"
            }
          ];
          this.filterSelected = this.filterArray;
          this.getGapClosureData();
          this.currentChart = "global-chart";
          break;
        case 2:
          this.activeTab = "plant";
          this.showLoader = true;
          this.applyFilterPlant = false;
          this.getPlantGapClosureData();
          this.currentChart = "plant-chart";
          break;
        case 3:
          this.activeTab = "pillar";
          this.showLoader = true;
          this.applyFilterPillar = false;
          this.getPillarGapClosureData();
          this.currentChart = "plant-chart";
          break;
        case 4:
          this.activeTab = "data";
          this.showLoader = true;
          this.applyFilterData = false;
          this.getDataGapClosureData();
          break;

        default:
          break;
      }
      this.dataStatus();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    applyFilter() {
      console.log("applyFilter");
      this.handleDrillDownPlant = false;
      let filterSelected = this.filterSelected;

      if (this.activeTab === "global") {
        this.selectedYear = filterSelected[0].arrayValue[0];
        this.globalGapClosureData = {};
        this.filterArray = [];
        this.filterArray = [
          {
            labelName: "Year",
            arrayValue: Helper.yearArrayFrom2020(), //[this.previousYear, this.currentYear],
            id: "Year"
          }
        ];
        this.getGapClosureData();
      } else if (this.activeTab === "plant") {
        this.plantGapClosureData = {
          type: "Plant",
          label: [],
          completed: [],
          committed: [],
          status: [],
          notSustained: [],
          offtrack: [],
          aop: [],
        };
        if (this.filterSelected[0].arrayValue.length === 1) {
          this.applyFilterPlant = true;
          this.selectedYear = this.filterSelected[0].arrayValue[0];
          setTimeout(() => {
            this.getPlantGapClosureData();
          }, 100);
        } else
          setTimeout(() => {
            this.plantDataCorrection();
            this.showLoader = false;
          }, 100);

      } else if (this.activeTab === "pillar") {
        this.showLoader = true;
        this.pillarGapClosureData = {
          type: "Pillar",
          label: [],
          committed: [],
          completed: []
        };
        if (this.filterSelected[0].arrayValue.length === 1) {
          this.showLoader = true;
          this.applyFilterPillar = true;
          this.selectedYear = this.filterSelected[0].arrayValue[0];
          setTimeout(() => {
            this.getPillarGapClosureData();
          }, 100);
        } else
          setTimeout(() => {
            this.pillarDataCorrection();
            this.showLoader = false;
          }, 100);

      } else if (this.activeTab === "data") {
        this.showLoader = true;
        this.regionList = [];
        this.pillarsData = [];
        this.firstDataItems = [];
        this.dataItems = [];
        this.completedDataItems = [];
        this.committedDataItems = [];
        if (this.filterSelected[0].arrayValue.length === 1) {
          this.applyFilterData = true;
          this.selectedYear = this.filterSelected[0].arrayValue[0];
          setTimeout(() => {
            this.getDataGapClosureData();
          }, 100);
        } else
          setTimeout(() => {
            this.dataCorrection();
            this.showLoader = false;
          }, 100);
        //
      }

      this.hideModal();
    },
    resetFilter() {
      if (this.activeTab === "global") {
        this.selectedYear = this.currentYear;
        this.filterArray = [
          {
            labelName: "Year",
            arrayValue: Helper.yearArrayFrom2020(), //[this.previousYear, this.currentYear],
            id: "Year"
          }
        ];
        this.filterSelected = this.filterArray;
      } else if (this.activeTab === "plant") {
        this.applyFilterPlant = false;
        this.selectedYear = this.currentYear;
        this.getPlantGapClosureData();
      } else if (this.activeTab === "pillar") {
        this.applyFilterPillar = false;
        this.selectedYear = this.currentYear;
        this.getPillarGapClosureData();
      } else if (this.activeTab === "data") {
        this.applyFilterData = false;
        this.selectedYear = this.currentYear;
        this.getDataGapClosureData();
      }
      this.hideModal();
    },
    changedFilter(event) {
      console.log("changedFilter");
      let filterSelectedC = {};
      this.filterArray.map((val, index) => {
        if (event.target.value !== val.arrayValue[index] && val.id === "Year") {
          let temp = {
            id: val.id,
            arrayValue: [event.target.value],
            labelName: val.labelName
          };
          filterSelectedC = temp;
          return filterSelectedC;
        }
      });
      this.filterSelected[0] = filterSelectedC;
    }
  }
};
</script>
<style scoped>
#chart {
  padding: 0 2rem 2rem 0;
}

.outer-block {
  background-color: #fff;
  border-radius: 6px;
}

.tab-titles {
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-options {
  display: flex;
  margin-top: 2rem;
}

.tab-option-text {
  font: 1.16rem "BarlowR", sans-serif;
  cursor: pointer;
}

.in-action {
  margin-right: 1.688em;
}

.tab-active-option {
  font: 1.18rem "BarlowM", sans-serif;
}

.active-tab-scroll-hr {
  border-top: 3px #274ba7 solid;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1 !important;
  margin-top: 1rem;
}

.select-dd {
  width: 10%;
}

.button-dd {
  display: inline-flex;
}

.funnel-con {
  /* border-radius: 2rem;
  border: 1px solid #274ba7;
  margin-left: 1rem;
  font-size: 1.33rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; */
  font-size: 1.33rem;
}

.sample-span {
  margin-right: 1rem;
  font: 1.08rem "BarlowM", sans-serif;
  color: #313131;
}

.sample-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
}

.title-text {
  font: 1.33rem "BarlowSb", sans-serif;
  padding: 0 0 2rem 0;
}

.data-table tbody {
  font-size: 0.875em;
}

#reports-gc-data-table th div {
  color: #313131;
}

#reports-gc-data-table tbody tr:nth-child(odd) {
  background-color: #fafafa !important;
}

#reports-gc-data-table tbody tr:nth-child(even):hover {
  background-color: #fff !important;
}

#reports-gc-data-table tr td:first-child {
  padding-left: 1.25em !important;
}

#reports-gc-data-table tr th:first-child {
  padding-left: 1.25em !important;
}

.download-view {
  border-radius: 2rem;
  border: 1px solid #274ba7;
  margin-left: 1rem;
  font-size: 1.33rem;
  margin-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.download-view:hover {
  background: #e3e8f7;
}

.action-btn {
  border: 1px #274ba7 solid;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  cursor: pointer;
}

.hr {
  position: relative;
  top: -26px;
}

.center-aligned {
  text-align: center;
  padding: 0 !important;
}

#data-block {
  padding: 0 2rem;
}

#data-block>div.data-table.gn-table.table-responsive {
  height: 40rem;
}
</style>
